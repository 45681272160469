header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 70px;
    background-color: var(--moment-background-color);
    box-shadow: 0 6px 6px 2px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.justify-self-start {
    justify-self: flex-start;
    margin-right: auto;
}

#soonfeed-link img {
    padding: 5px;
    margin-left: 12px;
}

.logo-beforetime-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.feed-socials-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.moments-beforetime {
    border-left: 2px solid rgba(0, 0, 0, 0.6);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 13px;
    padding-left: 4px;
    margin-left: 7px;
    height: fit-content;
}

.moments-beforetime p {
    margin: 0;
    line-height: 13px;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    padding: 28px 28px;
    background-color: var(--footer-background-color);
    color: var(--footer-background-font-color);
    margin-top: 50px;
}

footer h6 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}

footer a {
    text-decoration: none !important;
    color: #fff !important;
}

footer a:hover {
    text-decoration: none !important;
    color: #ddd !important;
}

.socials {
    margin-right: 20px;
    margin-left: 20px;
}

.socials .socials-item {
    text-decoration: none;
    background-color: var(--black-color);
    border-radius: 50%;
    padding: 5px;
}

.socials .socials-item i {
    color: var(--black-icon-color);
    font-size: 22px;
    margin: 5px;
}

.socials .socials-item:hover {
    cursor: pointer;
    background-color: var(--black-color-hover);
}

.menu-icon {
    margin-right: 20px;
    width: 30px;
}

.menu-icon img {
    width: 100%;
}

.menu-icon:hover {
    cursor: pointer;
}

.twitter-dropdown-overlay,
.menu-dropdown-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}

.twitter-dropdown,
.menu-dropdown {
    display: flex;
    flex-direction: column;
    background-color: var(--featured-background-color);
    border-radius: 6px;
    position: absolute;
    right: 20px;
    user-select: none;
    overflow: hidden;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.16);
    min-width: 200px;
    max-width: fit-content;
}

.menu-dropdown {
    align-items: flex-start;
}

.twitter-dropdown a,
.menu-dropdown a {
    padding: 15px;
    text-decoration: none;
    text-align: center;
    color: var(--featured-background-font-color);
    font-weight: 500;
    display: inline-block;
    border-bottom: 1px;
    width: 100%;
}

.menu-dropdown a {
    text-align: left;
}

.twitter-dropdown a:hover,
.menu-dropdown a:hover {
    background-color: var(--featured-background-color-hover);
}

.content-feed-wrapper {
    padding-top: 80px;
    max-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(122.1deg, rgba(230, 238, 246, 0.32) 13.12%, rgba(217, 217, 217, 0) 69.29%);
}

.content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    user-select: none;
}

.content-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: fit-content;
}

.content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 50%;
    max-width: 650px;
}

.featured-moment-wrapper {
    border-left: 4px solid var(--main-color);
    padding-left: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.featured-moment-wrapper>h3 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 16px;
}

.featured-moment-wrapper .moment {
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    min-height: 140px;
}

.featured-moments-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.featured-moment {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    min-width: var(--featured-main-min-width);
    max-width: 100%;
    aspect-ratio: 1;
    border-radius: 24px 24px 0 0;
    overflow: hidden;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
}

.featured-moment:nth-child(1) {
    animation: 0.5s ease 0s 1 featured-appear;
    animation-fill-mode: both;
}

.secondary-featured-moment:nth-child(1) {
    animation: 0.8s ease 0.15s 1 secondary-featured-appear;
    animation-fill-mode: both;
}

.secondary-featured-moment:nth-child(2) {
    animation: 0.8s ease 0.25s 1 secondary-featured-appear;
    animation-fill-mode: both;
}

.secondary-featured-moment:nth-child(3) {
    animation: 0.8s ease 0.35s 1 secondary-featured-appear;
    animation-fill-mode: both;
}

.secondary-featured-moment:nth-child(4) {
    animation: 0.8s ease 0.45s 1 secondary-featured-appear;
    animation-fill-mode: both;
}

.secondary-featured-moment:nth-child(5) {
    animation: 0.8s ease 0.55s 1 secondary-featured-appear;
    animation-fill-mode: both;
}

.featured-moment-img {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
}

.featured-moment-img img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.featured-moment-tags {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 35%;
    min-height: 20%;
    max-height: 35%;
    padding: 18px;
    padding-bottom: 0;
    position: absolute;
    right: 0;
}

.featured-moment-tags .tag {
    margin: 0;
    margin-bottom: 10px;
}

.featured-moment-tags .tag:not(:last-child) {
    margin-bottom: 10px;
}

.featured-moment-timestamp-join {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 65%;
    min-height: 35%;
    max-height: 65%;
}

.featured-moment-timestamp {
    position: relative;
    background-color: var(--featured-background-color);
    min-width: unset;
    width: unset;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 38% 0 0 / 0 31% 0 0;
    min-height: 80px;
    max-height: 150px;
    width: 30%;
    min-width: 65px;
    max-width: 122px;
    aspect-ratio: 0.81;
}

.featured-moment-join {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    width: 35%;
    aspect-ratio: 2.1;
    padding: 10px;
    border-top-left-radius: 14px;
    background-color: var(--success-color);
    color: var(--success-font-color);
}

.featured-moment-join p {
    margin: 0;
}

.featured-moment-join.joined,
.featured-moment-join.joined:hover,
.featured-moment-join.joined:focus {
    border: 2px solid var(--success-color);
    background-color: rgba(0, 0, 0, 0.45);
    color: var(--success-color);
    font-size: 22px;
}

.featured-moment.over .featured-moment-join {
    background-color: var(--success-color-disabled) !important;
    cursor: auto !important;
    user-select: none !important;
    opacity: 0.9;
}

.featured-moment.over .featured-moment-join.joined {
    border: 2px solid var(--success-color-disabled) !important;
    background-color: transparent !important;
    color: var(--success-color-disabled) !important;
}

.featured-moment-join:hover {
    cursor: pointer;
    background-color: var(--success-color-hover);
}

.featured-moment-join:active {
    cursor: pointer;
    background-color: var(--success-color-active);
}

.featured-moment-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 14px 17px;
}

.featured-moment-title {
    display: flex;
    width: 100%;
}

.featured-moment-title h3 {
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 6px;
}

.featured-moment-author-info-share {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.featured-moment-author {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.featured-moment-author .moment-author-avatar {
    width: 20px;
    height: 20px;
}

.featured-moment-author .moment-author-name {
    color: #D9D9D9;
    font-weight: 700;
}

.featured-moment-info-share {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.featured-moment-info-share a {
    text-decoration: none;
}

.featured-moment-info-share .open-link {
    width: 28px;
    height: 28px;
}

.featured-moment-info-share .open-link i {
    font-size: 15px;
}

.featured-moment-info-share .more-info {
    width: 28px;
    height: 28px;
}

.featured-moment-info-share .more-info i {
    font-size: 15px;
}

.featured-moment-info-share .share {
    width: 36px;
    height: 28px;
}

.featured-moment-info-share .share i {
    font-size: 15px;
}

.secondary-featured-moments {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: var(--featured-secondary-total-width);
    height: 100%;
    margin-left: var(--featured-main-margin);
    overflow: hidden;
}

.secondary-featured-moment {
    display: flex;
    position: relative;
    height: calc(50% - 8px);
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    overflow: hidden;
}

.secondary-featured-moment:hover {
    cursor: pointer;
    filter: brightness(0.75);
    user-select: none;
}

.secondary-featured-moment:not(:last-child) {
    margin-bottom: var(--featured-secondary-margin);
}

.secondary-featured-moment img {
    object-fit: cover;
}

.secondary-featured-moment .secondary-featured-moment-timestamp {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--featured-background-color);
    min-width: unset;
    width: unset;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 40% 0 0 / 0 32.5% 0 0;
    height: 58%;
    min-height: 65px;
    max-height: 140px;
    aspect-ratio: 0.81;
}

.secondary-featured-moment .moment-start-top {
    position: relative;
    width: 100%;
    height: calc(70% - 16px);
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--featured-background-font-color);
    font-weight: bold;
    font-size: 25px;
}

.soonfeed-logo {
    max-width: 320px;
    margin: 0 auto;
}

.soonfeed-logo img {
    width: 100%;
    height: auto;
}

#soonfeed-main-title {
    font-weight: 300;
    font-size: 20px;
    color: var(--light-gray-font-color);
    letter-spacing: 1.5px;
    text-align: center;
}

.soonfeed-info-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.soonfeed-info {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 40px;
}

.soonfeed-info-title {
    color: var(--moment-background-font-color);
}

.moments-horizontal-divider {
    margin: 0px 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    height: 1.6px;
    background-color: var(--moment-divider-color);
}

.moment-vertical-divider {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    width: 1.6px;
    background-color: var(--moment-divider-color);
}

.vertical-divider {
    display: flex;
    flex-direction: column;
    width: 5px;
    margin-right: 10px;
    background-color: var(--main-color);
}

.soonfeed-info h4,
.soonfeed-info h5,
.soonfeed-info h6 {
    text-align: left;
    margin: 0;
}

.soonfeed-info .info {
    color: var(--moment-background-font-color);
    margin-bottom: 28px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.soonfeed-info .guide {
    color: var(--moment-background-font-color);
    margin-bottom: 28px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.soonfeed-info .guide a {
    cursor: pointer;
    user-select: none;
    background-color: var(--dark-hyperlink-color);
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 10px 3px 10px;
}

.guide-join-button {
    background-color: var(--success-color);
    color: var(--success-font-color);
    margin-left: 2px;
    margin-right: 2px;
    padding: 5px;
    border-radius: 5px;
}

.sf-logo {
    border-left: 15px solid var(--main-color);
    display: flex;
    align-items: center;
}

.sf-logo img {
    width: 95px;
    padding: 12px;
}

.sf-divider {
    display: flex;
    flex-direction: column;
    width: 1px;
    margin: 5px 0;
    background-color: var(--main-color);
    opacity: 0.5;
}

.soonfeed-title {
    max-width: 700px;
    height: 100%;
    margin: auto 0;
}

.soonfeed-title h5 {
    color: var(--moment-background-font-color);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin: 0;
}

.soonfeed-title .info {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

.soonfeed-title .guide {
    color: var(--light-gray-font-color);
    line-height: 1.7;
}

.title-join-button {
    background-color: transparent;
    color: var(--success-color);
    font-weight: 800;
    margin-left: 2px;
    margin-right: 2px;
    display: inline-block;
    line-height: 22px;
}

.about-feed {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #F3F7FB;
    width: 100%;
    max-width: 425px;
    margin: 12px;
}

.about-feed-divider {
    display: flex;
    margin: 30px;
    height: 6px;
    background-color: var(--main-color);
    border-radius: 6px;
}

.about-feed-title {
    margin: 0 40px;
    margin-bottom: 40px;
    font-size: 22px;
    font-weight: bold;
}

.about-feed-headline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 40px;
    margin-bottom: 30px;
}

.about-feed-headline h5 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

.about-feed-headline h6 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.about-feed-providers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 40px;
    margin-bottom: 30px;
}

.about-feed-providers h5 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.about-feed-providers .moment-author {
    margin-bottom: 12px;
}

.feeds-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.feed-wrapper {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 0 12px;
    border-left: 4px solid var(--main-color);
}

.feed-wrapper .feed-headline h3 {
    text-decoration: none !important;
    margin-top: 5px;
    color: var(--moment-background-font-color) !important;
    font-size: 18px;
    font-weight: 600;
}

.feed-wrapper:not(:first-child) .feed-headline h3 {
    margin-top: 20px;
}

.feed-button-link {
    text-decoration: none;
}

.feed-button {
    background-color: var(--main-color);
    width: fit-content;
    padding: 15px 28px 12px 28px;
    border-radius: 14px;
}

.feed-button:hover {
    background-color: var(--main-color-hover);
    cursor: pointer;
}

.feed-button:active {
    background-color: var(--main-color-active);
    cursor: pointer;
}

.feed-button h2 {
    color: var(--main-font-color);
    font-weight: 600;
    font-size: 18px;
    margin: 0;
}

.filters-container {
    max-width: 700px;
    margin: auto;
    background-color: var(--modal-background-color);
    border: 5px solid var(--main-color);
}

.filters-title p {
    width: 100%;
    background-color: var(--main-color);
    color: var(--main-font-color);
    margin: 0;
    padding: 5px 10px;
}

.filters-row {
    display: flex;
    padding: 20px 10px;
}

.filter-box {
    border-radius: 50px;
    padding: 5px 10px;
    margin: 0 5px;
    color: var(--main-font-color);
    background-color: var(--main-color);
    cursor: pointer;
    user-select: none;
}

.filter-box label,
.filter-box input {
    cursor: pointer;
    margin-left: 5px;
}

.moments-container {
    background: linear-gradient(122.1deg, rgba(230, 238, 246, 0.32) 13.12%, rgba(217, 217, 217, 0) 69.29%);
    max-width: 700px;
    min-width: 700px;
    margin: auto;
    margin-bottom: 20px;
    border-left: 4px solid var(--main-color);
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    overflow: hidden;
}

.moments-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #eaeaea;
    padding: 32px 40px 10px 40px;
    margin-bottom: 40px;
}

.moments-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 40px 10px 40px;
}

.moments-footer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: #eaeaea;
    padding: 28px 40px;
}

.moments-footer .guide {
    color: var(--moment-background-font-color);
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}

.moments-footer .guide a {
    cursor: pointer;
    user-select: none;
    background-color: var(--dark-hyperlink-color);
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 10px 3px 10px;
}

.moments-title {
    margin: 0;
    margin-bottom: 10px;
}

.moments-title h2 {
    color: var(--moment-background-font-color);
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin: 0;
}

.moment-content {
    display: contents;
}

.moment-title-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
}

.moment {
    background-color: var(--moment-background-color);
    height: 140px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.15);
    position: relative;
}

.moment.pinned {
    background-color: var(--featured-background-color);
}

.moment.over {
    background-color: var(--moment-background-color-disabled);
}

.moment.over.pinned {
    background-color: var(--featured-background-color-disabled);
}

.moment.highlight {
    animation: moment-highlight 1s ease 0.5s;
}

.Toastify__toast {
    cursor: auto;
}

.toast-join-success {
    font-family: barlow, Arial, Helvetica, sans-serif !important;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--main-color);
    padding-left: 20px;
}

.toast-join-buttons {
    display: flex;
}

.toast-join-cancel-button {
    margin-right: 5px;
    padding: 0;
    background-color: transparent;
    color: var(--danger-color);
    border: none;
}

.toast-join-calendar-button {
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--main-color);
    color: var(--main-font-color);
    border: none;
}

.toast-join-calendar-button:hover {
    background-color: var(--main-color-hover);
    color: var(--main-font-color);
}

@keyframes moment-highlight {
    0% {
        transform: scale(1.0);
        box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.15);
    }

    50% {
        transform: scale(1.02);
        box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.4);
    }

    100% {
        transform: scale(1.0);
        box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.15);
    }
}

@keyframes featured-appear {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

@keyframes secondary-featured-appear {
    0% {
        opacity: 0.15;
    }

    100% {
        opacity: 1;
    }
}

.moment-center {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    min-width: 0;
    padding: 16px 16px 12px 12px;
}

.moment-center-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.moment-center-middle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.moment-center-middle h3 {
    display: flex;
    min-height: fit-content;
    margin: 5px 0;
    overflow: hidden;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.moment-center-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.moment-author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.moment-author-avatar {
    display: flex;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
}

.moment-author-avatar img {
    object-fit: cover;
}

.moment-author-name {
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
    color: var(--moment-tag-color);
}

.moment-info-share {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.moment-info-share a {
    text-decoration: none;
}

.moment-bottom {
    display: flex;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    justify-content: space-between;
    margin: 10px;
}

.moment-left {
    background-color: var(--featured-background-color);
    min-width: 90px;
    width: 90px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.moment-right {
    min-width: fit-content;
    padding: 20px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.moment-start-top {
    position: relative;
    width: 100%;
    height: calc(70% - 16px);
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--featured-background-font-color);
    font-weight: bold;
    font-size: 25px;
}

.moment-start-top text {
    text-anchor: middle;
    fill: var(--featured-background-font-color);
    font-family: barlow, Arial, Helvetica, sans-serif !important;
    font-weight: 900;
}

.moment-start-bottom text {
    text-anchor: middle;
    fill: var(--main-font-color);
}

.moment-pinned {
    margin-left: 10px;
    margin-right: 5px;
    background-color: transparent;
}

.moment-pinned .moment-left {
    background-color: var(--featured-background-color);
}

.moment.over {
    background-color: var(--moment-background-color-disabled);
}

.moment.over.pinned {
    background-color: var(--featured-background-color-disabled);
}

.moment-pinned i {
    font-size: 18px;
    color: var(--featured-background-font-color);
}

.moment.pinned .moment-start-top {
    border-right: 1.6px solid var(--moment-divider-color);
}

.moment-join {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    width: 90px;
    height: 60px;
    padding: 10px;
    border-radius: 12px;
    background-color: var(--success-color);
    color: var(--success-font-color);
}

.moment-join.joined,
.moment-join.joined:hover,
.moment-join.joined:focus {
    border: 2px solid var(--success-color);
    background-color: var(--success-font-color);
    color: var(--success-color);
    font-size: 22px;
}

.moment.over .moment-join {
    background-color: var(--success-color-disabled) !important;
    cursor: auto !important;
    user-select: none !important;
}

.moment.over .moment-join.joined {
    border: 2px solid var(--success-color-disabled) !important;
    background-color: transparent !important;
    color: var(--success-color-disabled) !important;
}

.moment-join:hover {
    cursor: pointer;
    background-color: var(--success-color-hover);
}

.moment-join:focus {
    cursor: pointer;
    background-color: var(--success-color-focus);
}

.moment-start-bottom,
.moment-speculate {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--main-color);
    color: var(--main-font-color);
    font-weight: 600;
    font-size: 18px;
}

.moment.over .moment-start-bottom {
    background-color: var(--main-color-disabled);
}

.moment-speculate {
    font-size: 14px;
}

.moment-sponsored {
    color: var(--main-color);
    font-style: italic;
    font-size: 14px;
    font-weight: 500;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    max-width: fit-content;
    flex-grow: 1;
}

span.tag {
    height: 24px;
    padding: 5px 6px 3px 6px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--moment-divider-color);
    color: var(--moment-tag-color);
    border-left: 8px solid var(--main-color);
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
}

span.tag.publication {
    border-left-color: var(--publication-color);
}

span.tag.live-media {
    border-left-color: var(--live-media-color);
}

span.tag.speculation {
    border-left-color: var(--speculation-color);
}

span.by {
    height: 20px;
    padding: 3px 0;
    margin: 0 10px;
    text-align: center;
    font-size: 12px;
}

.sponsor-name {
    height: 20px;
    padding: 3px 10px;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
    background-color: var(--gray-surface-color);
}

span.share {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 24px;
    border-radius: 4px;
    border: 1.6px solid var(--moment-tag-color);
    background-color: #fff;
}

span.share i {
    font-size: 12px;
    color: var(--moment-tag-color);
}

span.share:hover {
    cursor: pointer;
    background-color: #eee;
}

.share-dropdown-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}

.share-dropdown {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    right: 35px;
    bottom: -10px;
    user-select: none;
    overflow: hidden;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
    z-index: 100;
}

.share-dropdown .share-icon,
.share-dropdown .share-icon a {
    display: flex;
}

.share-dropdown img {
    align-self: center;
    padding: 10px;
    width: 40px;
    height: auto;
}

.share-dropdown .share-icon:hover {
    background-color: #eee;
    cursor: pointer;
}

span.open-link {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1.6px solid var(--moment-tag-color);
    background-color: #fff;
}

a span.open-link i {
    font-size: 12px;
    color: var(--moment-tag-color);
    text-decoration: none;
}

span.open-link:hover {
    cursor: pointer;
    background-color: #eee;
}

span.more-info {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1.6px solid var(--moment-tag-color);
    background-color: #fff;
}

a span.more-info {
    font-size: 12px;
    font-style: italic;
    color: var(--moment-tag-color);
    text-decoration: none;
}

span.more-info:hover {
    cursor: pointer;
    background-color: #eee;
}

.atcb-button {
    box-shadow: none !important;
    font-family: barlow, Arial, Helvetica, sans-serif !important;
    font-weight: 600;
}

.atcb-list {
    color: var(--atcb-modal-text) !important;
    border-color: var(--atcb-modal-background) !important;
}

.atcb-list-item {
    background-color: var(--atcb-modal-background) !important;
    border-color: #ccc !important;
}

.atcb-list-item:hover {
    background-color: var(--atcb-modal-background-2) !important;
    color: var(--atcb-modal-text-2) !important;
}

.atcb-icon svg:not(.atcb-icon-trigger):not(.atcb-icon-yahoo):not(.atcb-icon-ms365) {
    fill: var(--atcb-modal-text) !important;
}

.Toastify__close-button {
    position: absolute;
    right: 8px;
}

@media screen and (min-width: 512px) {
    .mobile {
        display: none !important;
    }
}


@media screen and (max-width: 1024px) {

    .content {
        padding: 0
    }

    .moments-page .content {
        flex-direction: column-reverse;
    }

    .content.no-reverse {
        flex-direction: column;
    }

    .content-left {
        width: 100%;
        padding-bottom: 0;
        padding: 5px;
    }

    .content-right {
        padding-bottom: 0;
        width: 60%;
        min-width: var(--content-right-min-width);
    }

    .moments-page .content-right {
        padding-bottom: 0;
        width: 100%;
        min-width: 100%;
    }

    .about-feed {
        width: 100%;
        max-width: 700px;
        margin: 10px;
        order: 2;
    }

    .soonfeed-info {
        padding-right: 0;
    }

    .soonfeed-info .info {
        margin-bottom: 20px;
    }

    .soonfeed-info .guide {
        margin-bottom: 20px;
    }

    .feeds-container {
        background-color: var(--moment-background-color);
        padding: 20px 0;
        border-left: 4px solid var(--main-color);
    }

    .feed-wrapper {
        border-left: none;
    }

    .featured-moment-wrapper>h3 {
        margin-top: 2px;
    }

    .moments-container {
        max-width: 700px;
        min-width: unset;
        width: 100%;
    }

    .moments-title {
        margin-bottom: 14px;
    }

    .moments-title h2 {
        font-size: 22px;
        line-height: 22px;
    }

    .soonfeed-title h5 {
        font-size: 12px;
    }

    .soonfeed-title .info {
        margin-bottom: 8px;
    }

    .moment {
        background-color: var(--moment-background-color);
        height: 120px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 6px;
    }

    .moment-center {
        padding: 8px;
    }

    .moment-center-middle {
        flex-grow: 1;
        align-items: flex-start;
        padding-top: 10px;
    }

    .moment-center-middle h3 {
        margin: 4px 0;
        font-size: 11px;
        line-height: 11px;
    }

    .moment-bottom {
        height: 16px;
        min-height: 16px;
        max-height: 16px;
        margin: 8px;
    }

    .moment-left {
        min-width: 70px;
        width: 70px;
    }

    .moment-right {
        padding: 18px 10px;
    }

    .moment-start-top {
        height: calc(65% - 16px);
    }

    .moment-start-bottom {
        height: calc(35%);
    }

    .moment-pinned {
        margin-left: 6px;
        margin-right: 2px;
    }

    .moment-pinned i {
        font-size: 12px;
    }

    .moment-join {
        font-size: 20px;
        width: 60px;
        height: 40px;
        padding: 6px;
        border-radius: 10px;
    }

    .moment-join.joined,
    .moment-join.joined:hover,
    .moment-join.joined:focus {
        font-size: 15px;
    }

    .moment-sponsored {
        font-size: 11px;
        font-weight: 600;
    }

    .main-horizontal-divider {
        margin: 5px auto 40px auto;
        display: flex;
        flex-direction: row;
        width: 60px;
        height: 1.6px;
        background-color: var(--main-color);
    }
}

@media screen and (max-width: 756px) {
    #soonfeed-link img {
        height: 40px;
    }

    .socials .socials-item i {
        font-size: 16px;
    }

    .feed-button {
        padding: 17px 14px 14px 14px;
    }

    .feed-button>h2 {
        font-size: 14px;
    }

    .content {
        flex-direction: column-reverse;
    }

    .content-right {
        padding: 5px;
        width: 100%;
        max-width: 600px;
    }

    .featured-moment-wrapper:not(.featured-single) {
        max-width: none;
        padding-left: 5px;
        border: none;
    }

    .featured-moment-wrapper.featured-single {
        padding: 32px;
        padding-right: 100px;
    }

    .featured-moment-wrapper>h3 {
        margin-top: 16px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 512px) {
    .desktop {
        display: none !important;
    }

    header {
        height: 65px;
    }

    footer {
        align-items: flex-start;
    }

    footer div>h6 {
        margin: 1px 0;
    }

    footer>h6 {
        margin: 16px 0;
    }

    .socials {
        margin-left: 8px;
    }

    .content {
        padding: 0;
    }

    #soonfeed-link {
        margin-right: 20px;
    }

    #soonfeed-link img {
        padding: 0;
        height: 50px;
    }

    .about-feed {
        width: 100%;
        max-width: calc(100% - 24px);
        margin: 10px;
        order: 2;
    }

    .feed-button {
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        width: fit-content;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feed-button>h2 {
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        width: fit-content;
    }

    .soonfeed-info-container {
        margin-bottom: 0;
    }

    .soonfeed-info {
        padding: 0 15px;
        width: 100%;
    }

    .soonfeed-info .info {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: justify;
        letter-spacing: 0.02em;
    }

    .soonfeed-info .guide {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: justify;
        letter-spacing: 0.02em;
    }

    .content-left {
        padding: 5px 0;
    }

    .content-right {
        padding: 5px 0;
    }

    .feeds-container {
        display: flex;
        flex-direction: column;
        background-color: var(--moment-background-color);
        border-left: 4px solid var(--main-color);
        padding: 20px 0;
    }

    .feed-wrapper {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        padding: 0 12px;
        border-left: none;
    }

    .featured-moments-container {
        flex-direction: column;
    }

    .featured-moment-wrapper.featured-single {
        padding: 0;
    }

    .featured-moment-wrapper {
        width: 100%;
        flex-direction: column;
        padding-left: 12px !important;
        padding-right: 12px !important;
        border-left: none;
        max-width: none;
    }

    .featured-moment-wrapper>h3 {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .featured-moment {
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .secondary-featured-moments {
        flex-direction: row;
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }

    .secondary-featured-moment {
        width: calc(50% - 3px);
    }

    .secondary-featured-moment:not(:last-child) {
        margin: 0;
        margin-right: 2%;
    }

    .moments-container {
        margin-top: 28px;
        margin-left: 10px;
        margin-right: 10px;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        width: calc(100% - 20px);
    }

    .moments-header {
        padding: 12px 20px 5px 16px;
        margin-bottom: 15px;
    }

    .moments-title {
        margin-bottom: 8px;
    }

    .moments-title h2 {
        font-size: 24px;
        line-height: 24px;
    }

    .moments-wrapper {
        padding: 0;
        padding-left: 8px;
        padding-right: 8px;
    }

    .moments-footer {
        margin-top: 10px;
        padding: 12px 16px;
    }

    .moments-footer .guide {
        font-size: 16px;
    }

    .soonfeed-title .info {
        font-size: 15px;
    }

    .moment {
        margin: 0;
        box-shadow: none;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 0;
        height: 100px;
        background-color: var(--mobile-moment-background-color);
    }

    .moment-pinned {
        position: absolute;
        top: -9px;
        right: 4px;
    }

    .moment-left {
        min-width: 65px;
        width: 65px;
        background-color: var(--mobile-featured-background-color);
    }

    .pinned .moment-left {
        min-width: 65px;
        width: 65px;
        background-color: var(--mobile-featured-background-color-active);
    }

    .moment-start-top {
        height: calc(70% - 16px);
    }

    .moment-start-bottom {
        height: 30%;
    }

    .tags-container {
        height: fit-content;
        min-height: unset;
        max-height: none;
        margin-top: 5px;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    span.tag {
        margin: 0;
        margin-bottom: 5px;
    }

    .moment-author-avatar {
        width: 20px;
        height: 20px;
    }

    .moment-right {
        min-width: 114px;
        padding: 0;
        justify-content: space-between;
        align-items: flex-end;
        overflow: visible;
    }

    .moment-info-share {
        margin: 10px;
        margin-top: 12px;
        align-self: flex-end;
        flex-direction: row;
    }

    .moment-info-share span i {
        font-size: 15px !important;
    }

    span.open-link {
        margin-right: 10px;
        width: 28px;
        height: 28px;
    }

    span.more-info {
        margin-right: 10px;
        width: 28px;
        height: 28px;
    }

    span.share {
        width: 28px;
        height: 28px;
    }

    .share-dropdown {
        right: 34px;
        bottom: -6px;
    }

    .share-dropdown img {
        padding: 8px;
        width: 36px;
    }

    .moment-join {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 25px;
        width: 114px;
        height: 60px;
        padding: 10px;
        border-radius: 0;
        border-top-left-radius: 14px;
        background-color: var(--success-color);
        color: var(--success-font-color);
    }

    .moment-join p {
        margin: 0;
    }

    .moment-join.joined,
    .moment-join.joined:hover,
    .moment-join.joined:focus {
        border: 2px solid var(--success-color);
        background-color: transparent;
        color: var(--success-color);
        font-size: 22px;
    }

    .moment.over .moment-join {
        background-color: var(--success-color-disabled) !important;
        cursor: auto !important;
        user-select: none !important;
    }

    .moment.over .moment-join.joined {
        border: 2px solid var(--success-color-disabled) !important;
        background-color: transparent !important;
        color: var(--success-color-disabled) !important;
    }

    .moment-join:hover {
        cursor: pointer;
        background-color: var(--success-color-hover);
    }

    .moment-join:focus {
        cursor: pointer;
        background-color: var(--success-color-focus);
    }

    .moment-title {
        background-color: var(--featured-background-color);
        margin: 0;
    }

    .moment.pinned+.moment-title {
        background-color: var(--mobile-featured-background-color-active);
        margin: 0;
    }

    .moment-title h3 {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin: 10px;
        margin-top: 7px;
    }

    a.disabled {
        pointer-events: none;
        cursor: default;
    }

    span.disabled {
        opacity: 0.5;
    }

    footer {
        margin-top: 10px;
        padding: 20px;
        padding-bottom: 60px;
    }

    .toast-join-buttons {
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 390px) {

    .soonfeed-title .info {
        font-size: 14px;
    }

    .moment-center {
        padding: 6px;
        padding-right: 0;
    }

    .tags-container {
        margin-top: 3px;
    }

    span.tag {
        height: 18px;
        padding: 5px 6px 3px 6px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
        background-color: var(--moment-divider-color);
        color: var(--moment-tag-color);
        border-left: 8px solid var(--main-color);
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
    }

    .moment-right {
        min-width: 96px;
    }

    .moment-info-share {
        margin: 5px;
        margin-top: 8px;
    }

    .moment-info-share span i {
        font-size: 12px !important;
    }

    span.open-link {
        margin-right: 4px;
        width: 24px;
        height: 24px;
    }

    span.more-info {
        margin-right: 4px;
        width: 24px;
        height: 24px;
    }

    span.share {
        width: 24px;
        height: 24px;
    }

    .moment-info-share .share-dropdown {
        right: 25px;
        bottom: -8px;
    }

    .moment-join {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        width: 86px;
        height: 43px;
        padding: 8px;
        border-radius: 0;
        border-top-left-radius: 14px;
        background-color: var(--success-color);
        color: var(--success-font-color);
    }

    .moment-join.joined,
    .moment-join.joined:hover,
    .moment-join.joined:focus {
        font-size: 18px;
    }

    .moment-author-avatar {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
    }

    .moment-author-name {
        font-size: 11px;
        font-weight: 600;
        margin-left: 4px;
    }

    .atcb-text {
        font-size: 14px !important;
        white-space: nowrap;
    }
}