.cookie-policy-page #main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.policy-content {
    max-width: 1400px;
    margin: 24px;
    background-color: var(--moment-background-color);
    padding: 40px;
    align-self: center;
    word-wrap: break-word;
    word-break: break-word;
}

.policy-content h2 {
    color: var(--dark-font-color);
    font-size: 42px;
}

.policy-content>div {
    margin-bottom: 40px;
}

.policy-content h3 {
    font-size: 22px;
    color: var(--black-color);
    margin-bottom: 20px;
}

.policy-content p {
    font-size: 18px;
    color: var(--black-color);
    opacity: 0.9;
    line-height: 22px;
    font-weight: 300;
}

.policy-content li {
    font-weight: 500;
    font-size: 18px;
}

.policy-content a {
    text-decoration: underline;
    font-weight: 700;
    color: var(--dark-hyperlink-color);
}

.policy-content a:hover {
    color: var(--dark-hyperlink-color-hover);
}

@media screen and (max-width: 756px) {

    .policy-content h2 {
        font-size: 38px;
    }
}

@media screen and (max-width: 512px) {
    .policy-content {
        margin: 0;
        padding: 10px;
        background-color: #fff;
    }

    .policy-content h2 {
        font-size: 28px;
    }

    .policy-content>div {
        margin-bottom: 25px;
    }

    .policy-content h3 {
        font-size: 20px;
        margin-bottom: 18px;
        font-weight: 500;
    }

    .policy-content p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .policy-content li {
        font-weight: 600;
        font-size: 14px;
    }

    .policy-content ul {
        padding-inline-start: 20px;
    }
}

@media screen and (max-width: 390px) {
    .policy-content {
        margin: 0;
    }

    .policy-content h2 {
        font-size: 22px;
    }

    .policy-content>div {
        margin-bottom: 18px;
    }

    .policy-content h3 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .policy-content p {
        font-size: 14px;
        line-height: 18px;
    }
}