.modal-container {
    position: fixed;
    width: fit-content;
    height: fit-content;
    left: 0;
    top: 0;
}

.modal-cover {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--black-color);
    opacity: 0.8;
}

.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    background-color: var(--modal-background-color);
    border-radius: 12px;
    overflow: hidden;
    user-select: none;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 14px;
    width: 100%;
    background-color: var(--modal-header-color);
    color: var(--modal-header-font-color);
}

.modal-title {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
}

.modal-close {
    margin: 5px;
    margin-left: 20px;
}

.modal-close:hover {
    cursor: pointer;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
}

.modal-subtitle {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.input-row {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
}

.checkbox-row {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--black-color);
}

.checkbox-row .consent-checkbox {
    margin: 0;
    margin-right: 8px;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--modal-background-color);
    font: inherit;
    color: currentColor;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border: 3px solid currentColor;
    border-radius: 2px;
    display: grid;
    place-content: center;
    cursor: pointer;
}

.checkbox-row .consent-checkbox::before {
    content: "";
    width: 12px;
    height: 12px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    opacity: 0;
    transition: all 0.1s ease;
    transform-origin: center;
    transform: rotate(10deg);
    box-shadow: inset 21px 21px var(--black-color);
    background-color: CanvasText;
}

.checkbox-row .consent-checkbox:checked::before {
    opacity: 1;
}

.checkbox-row .consent-checkbox:focus {
    outline: 1px solid rgba(0, 0, 0, 0.12);
    outline-offset: 0;
}

.checkbox-row p {
    font-size: 12px;
    font-weight: 700;
}

.checkbox-button-wrapper {
    display: flex;
    flex-direction: column;
    width: min-content;
    min-width: 368px;
}

.modal .button-row {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}


.modal button {
    padding: 10px 25px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    height: 45px;
    min-width: 130px;
}

.modal button:first-child {
    margin-left: 0 !important;
}
.modal button:last-child {
    margin-right: 0 !important;
}

button:hover {
    cursor: pointer;
}

.join-btn {
    background-color: var(--success-color);
    color: var(--success-font-color);
}

.join-btn:hover {
    background-color: var(--success-color-hover);
}

.join-btn.disabled {
    cursor: initial !important;
    background-color: var(--success-color-disabled);
}

.cancel-btn {
    background-color: #7A7A7A;
    color: #fff;
}

.cancel-btn:hover {
    background-color: #7f7f7f;
}

.modal label {
    flex: 3;
    margin: 5px;
}

.modal input:not(.consent-checkbox) {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding: 10px;
    padding-right: 30px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--black-color);
    border: 2px solid var(--modal-background-color);
    outline: none;
    border-radius: 8px;
    transition: all 0.5s ease;
    background-color: #F3F7FB;
    box-shadow: inset 4px 4px 4px -2px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
}

.modal .input-row input:not(:focus) {
    border: 2px solid var(--modal-background-color) !important;
}

.modal .input-row input::placeholder {
    color: var(--modal-placeholder-color);
}

i.validate-red,
i.validate-green {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}

i.validate-red {
    color: var(--main-color-active);
}

i.validate-green {
    color: var(--success-color);
}

.modal .input-row.validate-red input {
    border-color: var(--main-color-active);
}

.modal .input-row.validate-green input {
    border-color: var(--success-color);
}

.modal .button-row>div {
    display: contents;
}

@media screen and (max-width: 1024px) {
    .modal {
        width: 80%;
    }

    .modal button {
        padding: 8px 20px;
        margin: 8px;
        border: none;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
        height: 36px;
        min-width: 80px;
    }
}

@media screen and (max-width: 512px) {
    .modal {
        width: calc(100% - 36px) !important;
    }

    .modal .button-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 0;
    }

    .modal-content {
        padding: 25px;
    }

    .modal button {
        padding: 8px 20px;
        margin: 8px;
        border: none;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
        height: 42px;
        min-width: 80px;
    }

    .join-btn {
        flex-grow: 1;
        margin-right: 0;
    }

    .cancel-btn {
        margin-left: 0;
    }

        .checkbox-button-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-width: unset;
        }
}