.loader {
    position: fixed;
    top: 50%;
    left: calc(50% - 40px);
    fill: transparent;
    stroke: var(--main-color);
    stroke-width: 8;
    animation: dash 2s ease infinite, rotate 2s linear infinite;
}

.loader-relative {
    position: relative;
    top: 50%;
    left: calc(50% - 40px);
}

.loader-auto {
    position: relative;
    margin: auto;
    top: unset;
    left: unset;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 190;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 170, 190;
        stroke-dashoffset: -50;
    }

    100% {
        stroke-dasharray: 170, 190;
        stroke-dashoffset: -186;
    }
}

@keyframes dash2 {
    0% {
        stroke-dasharray: 1, 95;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 85, 95;
        stroke-dashoffset: -25;
    }

    100% {
        stroke-dasharray: 85, 95;
        stroke-dashoffset: -93;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}