:root {
  --main-color: #DB3526;
  --main-color-hover: #FF5647;
  --main-color-focus: #8d2922;
  --main-color-active: #EB4024;
  --main-color-disabled: #D9D9D9;
  --main-color-disabled-hover: #adadad;
  --main-font-color: #fff;
  --main-font-color-active: #fff;

  --trs-color: #931919;
  --trs-color-hover: #761414;

  --success-color: #3EB67A;
  --success-color-hover: #45D18B;
  --success-color-focus: #27784f;
  --success-color-active: #559E7A;
  --success-font-color: #fff;
  --success-font-color-active: #fff;
  --success-color-disabled: #D9D9D9;

  --black-color: #1E1E24;
  --black-color-hover: #3a3a43;
  --black-color-focus: #101013;
  --black-color-active: #13131b;
  --black-font-color: #fff;
  --black-font-color-active: #fff;
  --black-color-disabled: #414141;
  --black-icon-color: #fff;
  --black-icon-color-hover: #ccc;

  --moment-background-color: #F3F7FB;
  --moment-background-color-hover: #f8fbff;
  --moment-background-color-focus: #bdc7d0;
  --moment-background-color-active: #becbd8;
  --moment-font-color: #1E1E24;
  --moment-font-color-active: #1E1E24;
  --moment-background-color-disabled: #f5f5f5;
  --moment-tag-color: #7A7A7A;
  --moment-divider-color: #D9D9D9;

  --featured-background-color: #E6EEF6;
  --featured-background-color-hover: #fafdff;
  --featured-background-color-focus: #b9c9d8;
  --featured-background-color-active: #c7d1db;
  --featured-background-font-color: #1E1E24;
  --featured-background-font-color-active: #1E1E24;
  --featured-background-color-disabled: #f0f0f0;

  --mobile-moment-background-color: #F3F7FB;
  --mobile-moment-background-color-hover: #f8fbff;
  --mobile-moment-background-color-focus: #bdc7d0;
  --mobile-moment-background-color-active: #becbd8;
  --mobile-moment-font-color: #1E1E24;
  --mobile-moment-font-color-active: #1E1E24;
  --mobile-moment-background-color-disabled: #f5f5f5;
  --mobile-moment-tag-color: #7A7A7A;
  --mobile-moment-divider-color: #D9D9D9;
  --mobile-featured-background-color: #E6EEF6;
  --mobile-featured-background-color-hover: #fafdff;
  --mobile-featured-background-color-focus: #b9c9d8;
  --mobile-featured-background-color-active: #CFDEED;
  --mobile-featured-background-font-color: #1E1E24;
  --mobile-featured-background-font-color-active: #1E1E24;
  --mobile-featured-background-color-disabled: #f0f0f0;

  --gray-surface-color: #e0e0e0;
  --gray-surface-color-hover: #e7e7e7;
  --gray-font-color: #575757;
  --dark-font-color: #1e1e24;
  --light-gray-font-color: #7a7a7a;

  --modal-background-color: #E6EEF6;
  --modal-input-background-color: #F3F7FB;
  --modal-placeholder-color: #c4c4c4;
  --modal-header-color: #4978A7;
  --modal-header-font-color: #fff;

  --warning-color: #f5b841;
  --warning-color-hover: #fbcc6e;
  --warning-color-focus: #c99327;
  --warning-color-active: #e2a224;
  --warning-font-color: #333;
  --warning-font-color-active: #121212;
  --warning-color-disabled: #ddd;

  --danger-color: #c60001;
  --danger-color-hover: #df2a2a;
  --danger-color-focus: #960000;
  --danger-color-active: #b60d0d;
  --danger-font-color: #fff;
  --danger-font-color-active: #fff;
  --danger-color-disabled: #999;

  --dark-hyperlink-color: #3066BE;
  --dark-hyperlink-color-hover: #4d86e0;
  --dark-hyperlink-color-focus: #1d50a1;
  --dark-hyperlink-color-active: #0c54c7;

  --publication-color: #F5B841;
  --live-media-color: #3066BE;
  --speculation-color: #BF3174;

  --footer-background-color: #7A7A7A;
  --footer-background-font-color: #fff;

  --scrollbar-track-color: #E8E8E8;
  --scrollbar-thumb-color: #7A7A7A;
  --scrollbar-thumb-color-hover: #9c9c9c;
  --scrollbar-thumb-colora-active: #6b6b6b;



  /*How much of the total space content-right will take up for 756px-1024px*/
  --content-right-min-width: 50%;
  /*How much of the space the main featured moment will take up*/
  --featured-main-min-width: calc(66% - var(--featured-main-margin));
  /*Space between main and secondary featured moments*/
  --featured-main-margin: 14px;
  /*How much of the space the secondary moment will take up*/
  --featured-secondary-total-width: 33%;
  /*Space between secondary moments*/
  --featured-secondary-margin: 16px;


  /*Toastify overrides*/
  --toastify-color-success: #dd3326 !important;
  --toastify-toast-width: fit-content !important;
  --toastify-z-index: 9998 !important;

  /*Add to calendar button overrides*/
  --atcb-background: var(--main-color) !important;
  --atcb-background-2: var(--main-color-hover) !important;
  --atcb-border: var(--main-color) !important;
  --atcb-text: var(--main-font-color) !important;
  --atcb-text-2: var(--main-font-color) !important;
  --atcb-modal-background: #f5f5f5 !important;
  --atcb-modal-background-2: #fff !important;
  --atcb-modal-text: #333 !important;
  --atcb-modal-text-2: #000 !important;

}

/*Cookie Consent banner styles*/
.CookieConsent {
  background-color: var(--featured-background-color) !important;
  color: var(--dark-font-color) !important;
  box-shadow: 0 -4px 6px rgb(0 0 0 / 15%);
}

.CookieConsent>div {
  margin: 45px 12px !important;
  margin-bottom: 10px !important;
}

.CookieConsent>div+div {
  margin: 0 auto !important;
  margin-bottom: 10px !important;
}

#rcc-confirm-button {
  background-color: var(--main-color) !important;
  color: #fff !important;
  font-weight: 700 !important;
  border-radius: 12px !important;
  padding: 12px !important;
  font-size: 16px !important;
}

#rcc-confirm-button:hover {
  background-color: var(--main-color-hover) !important;
}

#rcc-confirm-button:active {
  background-color: var(--main-color-active) !important;
}

#rcc-decline-button {
  background-color: transparent !important;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
  margin: 0 !important;
  padding: 0 !important;
}

#rcc-decline-button img {
  width: 16px;
  height: 16px;
}

.CookieConsent a {
  font-weight: 700;
  color: var(--dark-hyperlink-color);
}

.CookieConsent a:hover {
  text-decoration: none;
  font-weight: 700;
  color: var(--dark-hyperlink-color-hover);
}

@media screen and (min-width: 485px) {

  .CookieConsent>div {
    margin: 40px 30px !important;
  }

  .CookieConsent>div+div {
    display: contents;
  }

  #rcc-confirm-button {
    margin: auto 15px !important;
    margin-right: 60px !important;
  }
}

/*
@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-black-italic-webfont.woff2') format('woff2'), url('fonts/barlow-black-italic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-black-webfont.woff2') format('woff2'), url('fonts/barlow-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-bold-italic-webfont.woff2') format('woff2'), url('fonts/barlow-bold-italic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-semibold-italic-webfont.woff2') format('woff2'), url('fonts/barlow-semibold-italic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-light-italic-webfont.woff2') format('woff2'), url('fonts/barlow-light-italic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-extralight-italic-webfont.woff2') format('woff2'), url('fonts/barlow-extralight-italic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-extralight-webfont.woff2') format('woff2'), url('fonts/barlow-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-thin-italic-webfont.woff2') format('woff2'), url('fonts/barlow-thin-italic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-thin-webfont.woff2') format('woff2'), url('fonts/barlow-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-italic-webfont.woff2') format('woff2'), url('fonts/barlow-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
*/

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-bold-webfont.woff2') format('woff2'), url('fonts/barlow-bold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-semibold-webfont.woff2') format('woff2'), url('fonts/barlow-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-medium-webfont.woff2') format('woff2'), url('fonts/barlow-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-light-webfont.woff2') format('woff2'), url('fonts/barlow-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlow';
  src: url('fonts/barlow-regular-webfont.woff2') format('woff2'), url('fonts/barlow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: barlow, Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-color-hover);
}

::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-thumb-colora-active);
}

::-webkit-scrollbar-corner {
  background: var(--scrollbar-track-color);
}