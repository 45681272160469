.admin-header {
    justify-content: space-around;
}

.admin-tab {
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--moment-background-font-color);
    text-decoration: none;
}

.admin-tab:hover {
    background-color: var(--moment-background-color-hover) !important;
    cursor: pointer;
}

.admin-tab .fa-sign-out-alt {
    margin-left: 10px;
}

.admin-content {
    padding-top: 50px;
    height: 100vh;
    overflow: auto;
}

.admin-moments {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.admin-feeds {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.admin-moments .feeds-container {
    padding: 20px;
}

.admin-moments .content-left {
    width: unset;
    padding-top: 50px;
    max-height: calc(100vh - 50px);
    overflow: auto;
}

.admin-moments .content-right {
    padding-top: 50px;
    flex-grow: 1;
    max-height: calc(100vh - 50px);
    overflow: auto;
}

.admin-feeds .content-left,
.admin-feeds .content-right {
    flex-grow: 1;
    overflow: auto;
}

.admin-editor {
    background-color: var(--modal-background-color);
    max-width: 900px;
    margin: 0 auto;
    border: 5px solid var(--main-color);
    border-radius: 20px;
    border-top-left-radius: 0;
    width: 100%;
}

.admin-moments .moments-container {
    max-width: 550px;
    padding: 20px;
}

.moment-select,
.feed-select {
    padding: 5px;
    cursor: pointer;
    width: 100%;
}

.moment-select:hover,
.feed-select:hover {
    background-color: var(--main-color-disabled-hover);
    cursor: pointer;
}

.moment-select.selected {
    background-color: var(--main-color-disabled);
}

.empty-editor {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-editor p {
    font-weight: 600;
    font-size: 18px;
}

.moment-edit {
    width: 100%;
    padding: 10px;
}

.moment-edit .edit-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--main-color);
    padding: 5px;
}

.moment-edit .edit-row label {
    width: 150px;
    font-size: 14px;
    font-weight: bold;
}

.moment-edit .edit-row input,
.moment-edit .edit-row select,
.moment-edit .edit-row textarea {
    flex-grow: 1;
    height: 30px;
    font-size: 14px;
    border: 1px solid var(--main-color);
    border-radius: 1px;
    outline: none;
    padding: 1px;
}

.moment-edit .edit-row textarea {
    height: auto;
}

.moment-edit .edit-row input:focus,
.moment-edit .edit-row select:focus,
.moment-edit .edit-row textarea:focus {
    border: 2px solid var(--main-color);
    padding: 0;
}

.moment-edit .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    margin-top: 20px;
}

.moment-edit .button-row button {
    height: 40px;
    margin: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 5px 15px #00000043;
}

.moment-edit .edit-row-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.moment-edit .edit-row-date .react-datetime-picker {
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
}

.moment-edit .edit-row-date .react-datetime-picker__wrapper {
    border: 1px solid var(--main-color);
}

.moment-edit .edit-row-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--main-color);
    padding: 5px;
}

.moment-edit .edit-row-image-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.moment-edit .edit-row-image-header label {
    width: 150px;
    font-size: 14px;
    font-weight: bold;
}

.moment-edit .edit-row-image-header input {
    flex-grow: 1;
    height: 30px;
    font-size: 14px;
    border: 1px solid var(--main-color);
    border-radius: 1px;
    outline: none;
    padding: 1px;
}

.moment-edit .edit-row-image-header input:focus {
    border: 2px solid var(--main-color);
    padding: 0;
}

.moment-image-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}

.moment-image-preview label {
    height: 30px;
    font-size: 16px;
    font-weight: bold;
}

.moment-image-preview img {
    width: auto;
    flex-grow: 1;
    height: 150px;
    border: 1px solid #888;
}

.moment-edit .edit-row-tags {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--main-color);
    padding: 5px;
}

.moment-edit .edit-row-tags .edit-row-tags-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid var(--main-color);
}

.moment-edit .edit-row-tags .edit-row-tags-header label {
    font-size: 14px;
    font-weight: bold;
}

.moment-edit .edit-row-tags .moment-selected-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.moment-edit .edit-row-tags .moment-selected-tag input {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.moment-edit .edit-row-tags .moment-selected-tag label {
    margin-left: 10px;
    font-weight: bold;
}

.moment-edit .edit-row-tags .moment-selected-tags {
    display: none;
}

.moment-edit .edit-row-tags.show-tags .moment-selected-tags {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.moment-datetime-header {
    align-self: center;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 10px;
}

.moment-datetime-CET,
.moment-datetime-UTC {
    font-weight: bold;
    margin: 5px;
}

.feed {
    background-color: var(--modal-background-color);
    width: 100%;
    margin: auto;
    border: 5px solid var(--main-color);
    border-radius: 20px;
    border-top-left-radius: 0;
}

.feed .feed-title {
    background-color: var(--main-color);
    width: 100%;
    padding: 10px;
    border-bottom-right-radius: 5px;
}

.feed .feed-title h2 {
    color: var(--main-font-color);
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.feed .feed-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.feed .feed-id {
    font-weight: 900;
}

.feed .feed-route {
    font-weight: 600;
}

.feed .feed-headline {
    padding: 5px;
    border-top: 1px solid var(--main-color);
}

.feed .feed-public {
    font-weight: 900;
    color: #fff;
    background-color: var(--success-color);
    padding: 5px;
    border-radius: 5px;
}

.feed-edit {
    width: 100%;
    padding: 10px;
}

.feed-edit .edit-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--main-color);
    padding: 5px;
}

.feed-edit .edit-row label {
    width: 30%;
    font-size: 14px;
    font-weight: bold;
}

.feed-edit .edit-row input {
    flex-grow: 1;
    height: 30px;
    font-size: 14px;
    border: 1px solid var(--main-color);
    border-radius: 1px;
    outline: none;
    padding: 1px;
}

.feed-edit .edit-row input:focus {
    border: 2px solid var(--main-color);
    padding: 0;
}

.feed-edit .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    margin-top: 20px;
}

.feed-edit .button-row button {
    height: 40px;
    margin: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 5px 15px #00000043;
}

.user {
    background-color: var(--modal-background-color);
    width: 500px;
    margin: auto;
    border: 5px solid var(--main-color);
    border-radius: 20px;
    border-top-left-radius: 0;
    margin-bottom: 20px;
}

.user-email {
    background-color: var(--main-color);
    width: 100%;
    padding: 10px;
    border-bottom-right-radius: 5px;
}

.user-email h2 {
    color: var(--main-font-color);
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.user-id-name {
    display: flex;
    flex-direction: column;
}

.user-id {
    font-weight: 900;
    margin: 5px;
}

.user-name {
    font-weight: 600;
    margin: 5px;
}

.user-create-update {
    display: flex;
    flex-direction: column;
}

.user-create {
    font-weight: 900;
    color: #fff;
    background-color: var(--success-color);
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
}

.user-update {
    font-weight: 900;
    color: #fff;
    background-color: var(--success-color);
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
}

.admin {
    background-color: var(--modal-background-color);
    width: 500px;
    margin: auto;
    border: 5px solid var(--main-color);
    border-radius: 20px;
    border-top-left-radius: 0;
    margin-bottom: 20px;
}

.admin-email {
    background-color: var(--main-color);
    width: 100%;
    padding: 10px;
    border-bottom-right-radius: 5px;
}

.admin-email h2 {
    color: var(--main-font-color);
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.admin-id-name {
    display: flex;
    flex-direction: column;
}

.admin-id {
    font-weight: 900;
    margin: 5px;
}

.admin-name {
    font-weight: 600;
    margin: 5px;
}

.admin-create-update {
    display: flex;
    flex-direction: column;
}

.admin-create {
    font-weight: 900;
    color: #fff;
    background-color: var(--success-color);
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
}

.admin-update {
    font-weight: 900;
    color: #fff;
    background-color: var(--success-color);
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
}

.button-create {
    height: 40px;
    margin: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 5px 15px #00000043;
    color: var(--success-font-color);
    background-color: var(--success-color);
    border: 2px solid var(--success-color-active);
}

.button-create:hover {
    background-color: var(--success-color-hover);
}

.button-create:focus {
    background-color: var(--success-color-focus);
}

.button-save {
    color: var(--success-font-color);
    background-color: var(--success-color);
    border: 2px solid var(--success-color-active);
}

.button-save:hover {
    background-color: var(--success-color-hover);
}

.button-save:focus {
    background-color: var(--success-color-focus);
}

.button-discard {
    color: var(--warning-font-color);
    background-color: var(--warning-color);
    border: 2px solid var(--warning-color-active);
}

.button-discard:hover {
    background-color: var(--warning-color-hover);
}

.button-discard:focus {
    background-color: var(--warning-color-focus);
}

.button-delete {
    color: var(--danger-font-color);
    background-color: var(--danger-color);
    border: 2px solid var(--danger-color-active);
}

.button-delete:hover {
    background-color: var(--danger-color-hover);
}

.button-delete:focus {
    background-color: var(--danger-color-focus);
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 26px;
    font-size: unset;
    font-weight: unset;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: var(--success-color);
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px var(--success-color);
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}